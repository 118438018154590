import { Button, Switch } from '@material-tailwind/react'
import React, { useState, useEffect, KeyboardEventHandler } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'

const CreateVotingSystem = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(true)
  const [pollOptions, setPollOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState([])
  const [question, setQuestion] = useState('')
  const [checked, setChecked] = useState(false)

  const components = {
    DropdownIndicator: null,
  }

  const createOption = (label) => ({
    label,
    value: label,
  })

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)])
        setInputValue('')
        event.preventDefault()
    }
  }

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //quantity reward
  const [valuePollDuration, setValuePollDuration] = useState(1)
  const incrementPollDuration = () => {
    setValuePollDuration((prevValue) => prevValue + 1)
  }
  const decrementPollDuration = () => {
    setValuePollDuration((prevValue) => (prevValue <= 1 ? 1 : prevValue - 1))
  }

  //create poll
  const handleCreatePoll = () => {
    const errors = []
    if (selectedRole === null) errors.push('Please select a role.')
    if (selectedChannel === null) errors.push('Please select a channel.')
    if (question === '') errors.push('Please enter a question.')
    if (value.length === 0) errors.push('Please enter at least one option.')
    if (errors.length > 0) {
      toast.error(errors.join('\n'), {
        style: { whiteSpace: 'pre-line' },
      })
      return
    }
    const pollData = {
      guildId: localStorage.getItem('guildId'),
      roles: selectedRole.value,
      channelId: selectedChannel.value,
      question: question,
      options: value.map((option) => option.value),
      duration: checked ? `${valuePollDuration}d` : `${valuePollDuration}h`,
    }
    HttpService.postCreatePoll(pollData)
      .then(() => {
        toast.success('Poll created successfully.')
        setSelectedRole(null)
        setSelectedChannel(null)
        setQuestion('')
        setValue([])
        setInputValue('')
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  return (
    <>
      <div className="container mx-auto flex flex-col gap-y-5 py-20">
        <div className="flex gap-x-2 items-start">
          {/* Role voting */}
          <SelectDefault
            options={dropdownRoles}
            value={selectedRole}
            onChange={(value) => setSelectedRole(value)}
            placeholder="Select Role"
            label="Role"
          />

          {/* Channel voting */}
          <SelectDefault
            options={dropdownChannels}
            value={selectedChannel}
            onChange={(value) => setSelectedChannel(value)}
            placeholder="Select Channel"
            label="Channel"
          />
          {/* Duration */}
          <div className="flex flex-col">
            <span className="text-sm">Duration</span>
            <div className="w-full flex flex-col justify-end bg-second rounded-lg border border-third">
              <div className="flex items-center h-full transition duration-300 rounded-lg shadow  bg-second border-second border ">
                <input
                  type="number"
                  min={1}
                  value={valuePollDuration}
                  onChange={(e) =>
                    setValuePollDuration(parseInt(e.target.value))
                  }
                  className=" h-full text-white p-2 first-of-type:pl-4 border-none bg-transparent w-16 outline-none"
                />
                <span className="text-sm w-12">
                  {checked ? '/days' : '/hours'}
                </span>
                <div className="flex flex-col h-full">
                  <button
                    onClick={incrementPollDuration}
                    className="bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
                  >
                    <BiPlus />
                  </button>
                  <hr className="border-second" />
                  <button
                    onClick={decrementPollDuration}
                    className="bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
                  >
                    <BiMinus />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-x-5 text-sm pt-0.5">
              <span>Hours</span>
              <Switch
                label="Days"
                className={`${checked ? 'bg-main' : 'bg-accentHouver'}`}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </div>
          </div>
        </div>
        {/* Poll question */}
        <div className="w-full flex flex-col justify-end">
          <span className="text-sm">Poll Question</span>
          <div className="w-full flex flex-col justify-end bg-second rounded border border-third ">
            <textarea
              rows={4}
              placeholder="Example: My Collection."
              className="w-full bg-second text-white border-b-[1px] border-transparent focus:border-mainHover focus:outline-none focus:ring-0 focus:ring-main focus:bg-accentMain/10 pl-5 py-2 placeholder:text-[#808080]"
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
        </div>
        {/* Poll-options */}
        <div className="w-full flex flex-col justify-end">
          <span className="text-sm">Poll Options</span>
          <div className="w-full flex flex-col justify-end bg-second rounded border border-third">
            <CreatableSelect
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder="Type something and press enter..."
              value={value}
              styles={{
                valueContainer: (provided, state) => ({
                  ...provided,
                  padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                  height: 'auto',
                  maxHeight: '100%',
                  color: '#fff',
                }),
                container: (provided, state) => ({
                  ...provided,
                  margin: 0,
                  padding: 0,
                  border: 0,
                  color: '#fff',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: '#fff',
                }),
                control: (provided, state) => ({
                  ...provided,
                  height: 'auto',
                  maxHeight: '100%',
                  maxWidth: '100%',
                  backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                  color: '#fff',
                  borderRadius: '0px',
                  borderColor:
                    state.isFocused || state.isHovered ? '#9945ff' : '#2C2F48',
                  borderWidth: '0 0 0.5px 0',
                  boxShadow:
                    state.isFocused || state.isHovered
                      ? '0 0 0 0.5px #9945ff'
                      : 'none',
                  paddingLeft: '0.5rem',
                  '&:hover': {
                    borderColor: 'none',
                  },
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: '#9945ff',
                  color: '#fff',
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: '#fff',
                }),
                input: (provided) => ({
                  ...provided,
                  color: '#fff',
                }),
              }}
            />
          </div>
        </div>
        {/* Button */}
        <div className="flex justify-end">
          <Button
            variant="gradient"
            color="deep-purple"
            onClick={handleCreatePoll}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateVotingSystem
