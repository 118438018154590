import React from 'react'
import Header from '../../components/Header/Header'
import SetupAccount from './_components/SetupAccount'

const Account = () => {
  return (
    <div className="text-white w-full flex flex-col bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left h-screen">
      <div className="w-full">
        <Header />
      </div>
      <div className="w-full h-full py-5 mb-10 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
        <SetupAccount />
      </div>
    </div>
  )
}

export default Account
