import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { SelectDefault } from '../../admin/components/ui/select'
import Switch from '../../admin/components/ui/switch'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'

const AntiBotMembers = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption)
  }

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption)
  }

  //button
  const saveClick = () => {
    if (!selectedChannel) {
      toast.error('Please select a channel!')
      return
    }
    if (!selectedRole) {
      toast.error('Please select a role!')
      return
    }
    const infoAntiBotMembers = {
      serverId: localStorage.getItem('guildId'),
      channelId: selectedChannel.value,
      roleId: selectedRole.value,
      captcha: isChecked,
    }
    HttpService.postUserVerification(infoAntiBotMembers)
      .then((response) => {
        setSelectedRole(null)
        setSelectedChannel(null)
        setIsChecked(false)
        toast.success('Message sent successfully!')
      })
      .catch((error) => {
        toast.error('Opss, something gone wrong.')
      })
  }
  return (
    <>
      <section className="container mx-auto text-white">
        <div className=" flex flex-col gap-y-8 ">
          <h1 className="font-semibold text-4xl">Anti bot members</h1>
          <h2 className="font-semibold text-2xl">User Verification</h2>

          <div className="flex lg:flex-row flex-col gap-5">
            {/* Select Channel */}
            <SelectDefault
              label="Select a Channel"
              placeholder="Select channel"
              isLoading={isLoading}
              options={dropdownChannels}
              value={selectedChannel}
              onChange={handleChannelChange}
            />
            {/* Select Role */}
            <SelectDefault
              label="Select a Role"
              placeholder="Select role"
              isLoading={isLoading}
              options={dropdownRoles}
              value={selectedRole}
              onChange={handleRoleChange}
            />
          </div>
          {/* Verification type */}
          <div className="flex lg:flex-row flex-col gap-5 transition-all duration-300 ease-in-out items-end justify-between lg:w-1/2 lg:pb-14">
            <Switch
              span="Verification type"
              isChecked={isChecked}
              handleCheckboxChange={() => setIsChecked(!isChecked)}
              labelLeft="Standard"
              labelRight="Captcha"
            />
          </div>
          <hr className="w-full border-third" />
          {/* Send Button */}
          <div className="w-full flex items-end justify-end">
            <Button
              onClick={saveClick}
              className="bg-main w-32 h-12"
              color="purple"
            >
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}
export default AntiBotMembers
