import { Button, Input, Progress } from '@material-tailwind/react'
import React, { useState } from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'

const DecentralizedUpload = ({ fetchImages }) => {
  const [storageSizeBar, setStorageSizeBar] = useState(79)
  const [storageSize, setStorageSize] = useState(215)
  const [preview, setPreview] = useState(null)
  const [nameFile, setNameFile] = useState('')
  const [file, setFile] = useState(null)

  const uploadFile = (event) => {
    const file = event.target.files[0]
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
        setFile(file)
      }
      reader.readAsDataURL(file)
    } else {
      alert('Por favor, selecione um arquivo de imagem.')
    }
  }

  const uploadStorage = () => {
    console.log('uploadStorage', file, nameFile)
  }

  return (
    <section className="w-full flex flex-col gap-y-5 ">
      <h1 className="text-4xl pb-5 font-bold">Decentralized Storage</h1>
      {/* cabeçalho e barra de armazenamento - implementar logica de armazenamento */}
      <div className="w-full flex flex-row justify-between items-end ">
        <p className="font text-lg tracking-wide w-1/2 ">
          Easily upload files to Shadow Drive, 1GB of storage included
        </p>
        <div className="w-1/2 h-full flex flex-col text-end  ">
          <span className="px-2">{storageSize} Mb left</span>
          <Progress
            value={storageSizeBar}
            size="sm"
            color="deep-purple"
            variant="gradient"
          />
        </div>
      </div>
      {/* botão e tela de upload */}
      <div className="flex flex-row justify-center items-center gap-x-5 ">
        <div
          className={
            'w-full transition-all duration-300 ' +
            (preview ? ' h-[25vh] ' : '')
          }
        >
          {preview ? (
            <div className="flex w-full h-full   gap-x-5 ">
              <div className="relative  h-full group">
                <img
                  src={preview}
                  alt="Preview"
                  className="object-contain w-full h-full p-1 rounded-sm"
                />
                <button
                  onClick={() => document.getElementById('fileUpload').click()}
                  className="absolute inset-0 items-center justify-center bg-black bg-opacity-50 text-white p-2 opacity-0 group-hover:opacity-100 transition-all duration-300 flex"
                >
                  Change
                </button>
                <input
                  type="file"
                  id="fileUpload"
                  className="hidden"
                  onChange={uploadFile}
                />
              </div>
              {/* Dados imagem */}

              <div className="w-1/2 h-full flex  ">
                <div className="w-full h-full flex flex-row items-end justify-end gap-x-2 p-2 ">
                  <Input
                    label="File name"
                    variant="outlined"
                    color="deep-purple"
                    className="focus:outline-none active:border-spacing-1  focus:bg-basic w-full text-white "
                    labelProps={{
                      className:
                        'peer-focus:text-main peer-focus:font-bold peer-focus:text-xs placeholder-shown:text-main',
                    }}
                    value={nameFile}
                    onChange={(e) => {
                      setNameFile(e.target.value)
                    }}
                  />
                  <div className="w-auto flex items-center justify-center gap-x-1">
                    <div className="w-24 h-10">
                      <Button
                        variant="text"
                        color="purple"
                        className="w-full h-full flex flex-row justify-center items-center gap-x-5 text-xs"
                        onClick={() => setPreview(null)}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="w-24 h-10">
                      <Button
                        variant="filled"
                        color="deep-purple"
                        className="w-full h-full flex flex-row justify-center items-center gap-x-5 text-lg"
                        onClick={uploadStorage}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <input
                type="file"
                id="fileUpload"
                style={{ display: 'none' }}
                onChange={uploadFile}
              />
              <div className="w-60 h-14 ">
                <Button
                  variant="outlined"
                  color="white"
                  className="w-full h-full flex flex-row justify-center items-center gap-x-5 text-lg "
                  onClick={() => document.getElementById('fileUpload').click()}
                >
                  Upload
                  <FaCloudUploadAlt size={20} />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default DecentralizedUpload
