import { Button } from '@material-tailwind/react'
import { FaDiscord } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive'
import Header from '../Header/Header'

export default function Login() {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const handleLoginDiscord = () => {
    const authUrl = process.env.REACT_APP_AUTH_DISCORD
    window.location.replace(authUrl)
  }

  return (
    <section className="h-screen w-full flex flex-col text-white bg-bgAdmin bg-cover bg-center bg-no-repeat">
      <div className="">
        <div>
          <Header />
        </div>
        <div className="flex flex-col justify-center items-center h-full">
          <div className="bg-second mx-5 shadow-lg rounded-lg p-8 flex flex-col items-center">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">
              Welcome to the Tektooks website.
            </h2>
            <h3 className="text-lg text-gray-600 mb-6">
              You need to login with your Discord account to access this
              feature.
            </h3>
            <Button
              onClick={handleLoginDiscord}
              type="button"
              className="capitalize text-white bg-mainHover px-5 flex items-center gap-x-3"
            >
              <FaDiscord className="lg:text-xl text-lg" />
              {isMobile ? 'Login' : 'Login with Discord'}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
