import { HttpService } from './axios-httpService'

export const fetchRoles = async (guildId) => {
  try {
    const response = await HttpService.getRolesByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching roles')
  }
}

export const fetchRolesReactions = async (guildId) => {
  try {
    const response = await HttpService.getRoleReactions(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching roles reactions')
  }
}

export const fetchRolesSelection = async (guildId) => {
  try {
    const response = await HttpService.getRoleSelections(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching roles selection')
  }
}
