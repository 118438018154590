import { IconButton } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { CgMenuGridR } from 'react-icons/cg'
import { FaBars, FaTimes } from 'react-icons/fa'
import { HiBars3CenterLeft } from 'react-icons/hi'
import { RiMenuFoldFill } from 'react-icons/ri'
import { RiMenu5Line } from 'react-icons/ri'
import { TbArrowsLeft } from 'react-icons/tb'
import { TbChartArrows } from 'react-icons/tb'
import { useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'

const MobileSidebar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const location = useLocation()

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  useEffect(() => {
    setIsDrawerOpen(false)
  }, [location])

  return (
    <>
      <IconButton variant="text" size="lg" onClick={openDrawer}>
        <RiMenu5Line size={28} className="text-main" />
      </IconButton>

      <div
        open={isDrawerOpen}
        onClose={closeDrawer}
        onKeyDownCapture={closeDrawer}
        className={`fixed top-0 left-0 w-full h-full bg-dark/70 z-10 transition-transform duration-300 ease-in-out ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        <div className="flex justify-between h-full">
          <div className={`bg-dark h-full w-full }`}>
            <Sidebar />
          </div>
          <div className="fixed left-5 top-5" onClick={closeDrawer}>
            <IconButton
              variant="text"
              color="blue-gray"
              className=" z-10"
              onClick={closeDrawer}
            >
              <RiMenuFoldFill size={28} className="text-main" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileSidebar
