import Cookies from 'js-cookie'
import React, { useState, useEffect } from 'react'
import { HttpService } from '../../../utils/axios-httpService'
import Guild from './Guild'
import Social from './Social'
import Wallets from './Wallets'

const SetupAccount = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [listGuilds, setListGuilds] = useState([])

  useEffect(() => {
    if (listGuilds && listGuilds.length > 0) return
    HttpService.getGuilds()
      .then((response) => {
        setListGuilds(response.data)
        localStorage.setItem('myServers', JSON.stringify(response.data))
      })
      .catch((error) => {
        console.error('Error fetching guilds:', error)
      })
  }, [])

  return (
    <section className="w-full h-full flex flex-col mx-auto container gap-5">
      <div className="flex flex-col w-full items-start gap-5">
        <div className="flex flex-col lg:flex-row w-full relative items-center justify-center">
          <img
            className="rounded-full h-42 lg:h-40"
            src={`https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`}
            alt="Avatar"
          />
          <div className="flex flex-col gap-y-1 w-full h-full items-center lg:items-start lg:justify-end pl-0 lg:pl-5 pt-5 lg:pt-0">
            <h2 className="text-2xl lg:text-4xl font-semibold flex">
              {userData.user.global_name}
            </h2>
            <Social />
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-5 h-full mt-14 justify-center">
        {/* lista de servidores com bot aqui */}
        {listGuilds.length > 0 && <Guild guilds={listGuilds} />}
        <hr className="w-full border-basic lg:hidden" />
        <div
          className={`${listGuilds.length > 0 ? 'w-full' : 'w-2/3 justify-center items-center'}`}
        >
          {/* lista de wallets aqui */}
          <Wallets />
        </div>
      </div>
    </section>
  )
}

export default SetupAccount
