import { Alert, Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import adBanner from '../../../img/AdBanner/TekrosSpeaker.png'

const AdBannerMobile = () => {
  const [openAlert, setOpenAlert] = useState(true)

  const getBot = () => {
    const newWindow = window.open(
      `${process.env.REACT_APP_BOT_INVITE}&disable_guild_select=false`,
      'popup',
      'width=600,height=900,scrollbars=no,resizable=no,return=true'
    )
    newWindow.focus()
  }

  return (
    <Alert
      icon={
        <img
          src={adBanner}
          alt="speaker"
          className="absolute bottom-0 right-0 "
        />
      }
      open={openAlert}
      onClose={() => setOpenAlert(false)}
      className="max-w-[400px] w-full bg-second/95 rounded-lg flex shadow-xl shadow-mainHover absolute z-50 p-4 items-center justify-center"
    >
      <div className="flex flex-col gap-y-2 justify-center items-center w-full relative pb-32 pt-10">
        <div className="flex tekros ">
          Protect your Discord server with our powerful global ban utility! With
          over 600 flagged bad actors, adding our bot instantly safeguards your
          community.
        </div>
        <span className="flex tekros pt-10">
          Don't wait—secure your server today
        </span>
        <div className="absolute bottom-16 mr-16">
          <Button
            variant="filled"
            color="deep-purple"
            className="bg-main "
            onClick={() => {
              getBot()
            }}
          >
            Protect your server
          </Button>
        </div>
      </div>
    </Alert>
  )
}

export default AdBannerMobile
