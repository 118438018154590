import React, { useState } from 'react'
import Switch from '../components/ui/switch.js'
import CreateTicket from './CreateTicket.js'

const TicketSystem = () => {
  return (
    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="container mx-auto flex justify-between items-center lg:pb-10">
        <h1 className="text-4xl font-bold">Ticket System</h1>
      </div>
      <div className="flex flex-col items-center justify-center container mx-auto rounded-lg p-5 gap-y-5">
        <hr className="w-full border-third" />
        <CreateTicket />
      </div>
    </section>
  )
}

export default TicketSystem
