import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'

const CombineRoles = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRoleOne, setSelectedRoleOne] = useState(null)
  const [selectedRoleTwo, setSelectedRoleTwo] = useState(null)
  const [selectedRoleReward, setSelectedRoleReward] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter(
      (role) =>
        (selectedRoleOne === null || role.id !== selectedRoleOne.value) &&
        (selectedRoleTwo === null || role.id !== selectedRoleTwo.value) &&
        (selectedRoleReward === null || role.id !== selectedRoleReward.value)
    )
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const handleCombineRoles = () => {
    const errors = []
    if (!selectedRoleOne) {
      errors.push('No role selected')
    }
    if (!selectedRoleTwo) {
      errors.push('No role selected')
    }
    if (!selectedRoleReward) {
      errors.push('No role to receive selected')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), {
        style: { whiteSpace: 'pre-line' },
      })
    } else {
      const request = {
        guildId,
        roleOneId: selectedRoleOne.value,
        roleTwoId: selectedRoleTwo.value,
        rewardRoleId: selectedRoleReward.value,
      }
      HttpService.putCombineRole(request)
        .then(() => {
          toast.success('Roles combined successfully')
          setSelectedRoleOne(null)
          setSelectedRoleTwo(null)
          setSelectedRoleReward(null)
        })
        .catch((error) => {
          toast.error(error.message)
        })
    }
  }
  return (
    <>
      <div className="w-full flex flex-col gap-5 pb-36">
        <div className="w-full flex gap-3">
          <SelectDefault
            placeholder="Select role for combine"
            label="Select role for combine"
            isLoading={isLoading}
            isDisabled={isLoading}
            options={dropdownRoles}
            value={selectedRoleOne}
            onChange={(value) => setSelectedRoleOne(value)}
          />
          <SelectDefault
            placeholder="Select role for combine"
            label="Select role for combine"
            isLoading={isLoading}
            isDisabled={isLoading}
            options={dropdownRoles}
            value={selectedRoleTwo}
            onChange={(value) => setSelectedRoleTwo(value)}
          />
          <SelectDefault
            placeholder="Select the role you will receive"
            label="Select a role"
            isLoading={isLoading}
            isDisabled={isLoading}
            options={dropdownRoles}
            value={selectedRoleReward}
            onChange={(value) => setSelectedRoleReward(value)}
          />
          <div className="flex gap-3 items-end">
            <Button
              variant="filled"
              color="purple"
              className="w-fit whitespace-nowrap h-12 bg-main"
              onClick={handleCombineRoles}
            >
              Combine Roles
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CombineRoles
