import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Input,
} from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BiPlus, BiSearch, BiX } from 'react-icons/bi'
import { CgTrash } from 'react-icons/cg'
import { TbCurrencySolana } from 'react-icons/tb'
import { toast } from 'react-toastify'
import { HttpService } from '../../../utils/axios-httpService'

const Wallets = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [socialAccount, setSocialAccount] = useState([])
  const [wallets, setWallets] = useState([])

  const getSocial = () => {
    HttpService.getMe().then((response) => {
      setSocialAccount(response)
      setWallets(response.data.wallets)
    })
  }
  useEffect(() => {
    if (!socialAccount || socialAccount.length === 0) {
      getSocial()
    }
  }, [])

  const shortAddress = (address) => {
    if (!address) return ''
    return `${address.substring(0, 7)} ... ${address.substring(address.length - 3)}`
  }

  const changeMainWallet = (wallet) => {
    if (wallet.isMain) {
      toast.info(`Wallet ${wallet.wallet} is already the main wallet.`)
      return
    }
    HttpService.patchUpdateMainWallet(wallet.wallet)
      .then(() => {
        toast.success(`Wallet ${wallet.wallet} is now the main wallet.`)
        getSocial()
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
  }

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [walletToDelete, setWalletToDelete] = useState(null)

  const deleteWallet = (wallet) => {
    HttpService.deleteAccountWalletById(wallet)
      .then(() => {
        toast.success('Wallet deleted successfully!')
        getSocial()
        setConfirmDelete(false)
      })
      .catch((error) => {
        toast.error(`Opss, wallet not deleted`)
        setConfirmDelete(false)
      })
  }

  const handleAddWallet = () => {
    const newWindow = window.open(
      'https://verification.tektools.app/',
      'popup',
      'width=600,height=600,scrollbars=no,resizable=no,return=true'
    )
  }

  const handleConfirmDelete = (wallet) => {
    setWalletToDelete(wallet)
    setConfirmDelete(true)
  }

  return (
    <div className="flex flex-col w-full  shadow-main relative">
      <Card className="bg-transparent h-full shadow-none ">
        <div className="flex justify-between items-end py-2 px-5">
          <span className="text-lg font-semibold">Wallets</span>
          <Button
            className="flex gap-x-1 items-center bg-main text-xs font-semibold"
            onClick={handleAddWallet}
          >
            Add New Wallet
            <BiPlus className="h-5 w-5 " />
          </Button>
        </div>
        {wallets.length > 0 ? (
          <>
            <Input
              placeholder="Filter by address"
              variant="static"
              color="deep-purple"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
              className="w-full bg-second border-none px-5 text-white"
              icon={<BiSearch className="text-xl text-main -ml-10" />}
            />
            <div className="h-full max-h-[350px] overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
              <table className="w-full text-left border border-second rounded-lg">
                <thead className="text-xs bg-second uppercase">
                  <tr>
                    <th scope="col" className="text-start py-2 pl-5 w-full">
                      Address
                    </th>
                    <th scope="col" className="text-start py-2 pl-5 w-36">
                      Wallet
                    </th>
                    <th scope="col" className="text-start py-2 pl-5"></th>
                  </tr>
                </thead>

                <tbody>
                  {wallets
                    ?.sort((a, b) => b.isMain - a.isMain)
                    ?.filter((item) =>
                      item.wallet.toLowerCase().includes(searchQuery)
                    )
                    ?.map((item, index) => (
                      <tr
                        className="bg-basic hover:bg-mainHover/30 border-second border-t border-b transition-all duration-300"
                        key={index}
                      >
                        <td className="px-5 py-2 border-r border-second flex items-center gap-x-2 tracking-wider text-sm font-semibold w-full justify-start ">
                          <TbCurrencySolana size={30} className="text-main" />
                          {/* {item.wallet} */}
                          {shortAddress(item.wallet)}
                        </td>
                        <td className="px-5 py-2 border-r border-second w-20 lg:w-36 transition-all duration-300 justify-end">
                          <div className="flex flex-row text-lg tracking-wide items-center text-center w-full justify-end">
                            <label className="relative inline-flex cursor-pointer select-none items-center">
                              <input
                                type="checkbox"
                                checked={item.isMain}
                                onChange={() => changeMainWallet(item)}
                                className="sr-only"
                              />
                              <span
                                className={`text-sm font-semibold ${item.isMain ? '' : ''}`}
                              >
                                {item.isMain ? 'Main' : 'Secondary'}
                              </span>
                              <div
                                className={`mx-4 flex h-8 w-16 items-center rounded-full p-1 duration-200 ${
                                  item.isMain ? 'bg-accentHouver' : 'bg-third'
                                }`}
                              >
                                <div
                                  className={`h-6 w-6 rounded-full bg-white duration-200 ${
                                    item.isMain ? 'translate-x-[30px]' : ''
                                  }`}
                                ></div>
                              </div>
                            </label>
                          </div>
                        </td>
                        <td className="w-10 h-8 hover:bg-accentHover">
                          <Button
                            color="deep-purple"
                            className="bg-main hover:bg-mainHover flex items-center justify-center text-white h-full w-10 p-px rounded-none rounded-l-lg"
                            onClick={() => handleConfirmDelete(item.wallet)}
                          >
                            <CgTrash size={20} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-start h-full pt-20">
            <span className="text-sm font-semibold italic tracking-wider">
              No wallets found, add a new wallet{' '}
              <span
                className="text-main hover:cursor-pointer"
                onClick={() => handleAddWallet()}
              >
                here
              </span>
              .
            </span>
          </div>
        )}
      </Card>
      {confirmDelete && (
        <div className="fixed top-0 left-0 w-full h-full bg-dark/80 flex justify-center items-center">
          <Card className="bg-basic lg:min-w-96 relative mx-10 shadow-xl shadow-main/30">
            <CardHeader className="m-0 px-5 py-3 rounded-b-none flex items-center bg-second uppercase font-semibold text-main">
              <span>confirm delete </span>
              <div className="absolute top-0 right-0">
                <IconButton
                  variant="text"
                  onClick={() => setConfirmDelete(false)}
                >
                  <BiX size={20} className="text-red-700" />
                </IconButton>
              </div>
            </CardHeader>
            <CardBody className="">
              I really want to delete a wallet{' '}
              <span className="font-semibold italic">{walletToDelete}</span> ?
            </CardBody>
            <CardFooter className="flex items-center justify-end gap-x-1 bg-second px-2 py-1 rounded-b-xl">
              <Button
                variant="text"
                className="text-red-700"
                onClick={() => setConfirmDelete(false)}
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color="deep-purple"
                onClick={() => deleteWallet(walletToDelete)}
              >
                Delete
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </div>
  )
}

export default Wallets
